import React from "react"
import { graphql } from "gatsby"
import { Grid, Col, Row, Jumbotron } from 'react-bootstrap';
import HTMLContent from '../../components/Content';
import Layout from '../../components/layout';

export default function splashPagesTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  
//   console.log('============================================================= splashPagesTemplate data')
//   console.log(data)
  const { markdownRemark } = data // data.markdownRemark holds your post data

  if (markdownRemark && markdownRemark !== null) {
      const {frontmatter} = markdownRemark;
      const bannerImage = {};
      const scrollToBottom = (location) => {
          $('html,body').animate({
          scrollTop: $(location).offset().top },
          'fast');
      };
      if(frontmatter.banner && frontmatter.banner.background) {
          bannerImage.backgroundImage = `url(${frontmatter.banner.background})`
      }
      return (  
        <Layout>
          <Grid fluid className="auto-width">
              <Row>
              <Col xs={12} className="full-width no-padding">
                  <Jumbotron className="jumbo" style={bannerImage}>
                  <Grid className="jumbo-grid">
                      <Row className="flex justify-center jumbo-row">
                      <Col xs={12} className="gray-col">
                          <h2 className="welcome">{frontmatter.banner.welcome}</h2>
                          <h2 className="header">{frontmatter.banner.header}</h2>
                          <p className="more">{frontmatter.banner.description}</p>
                          <p><HTMLContent className="header-subdescription" content={frontmatter.banner.subdescription}></HTMLContent></p>
                      </Col>
                      </Row>
                  </Grid>
                  </Jumbotron>
              </Col>
              </Row>
              <Row>
              <Col xs={12} className="full-width second-col" id="jump2">
                  <Grid>
                  <Row className="col-content-wrapper">
                      <Col sm={12} md={4} className="image-wrapper no-padding">
                      <img src={frontmatter.whoWeAre.featureImage} />
                      </Col>
                      <Col sm={12} md={8} className="content-wrapper">
                      <div className="wrapper-one">
                          <div className="left-white-border">
                          <p className="semibold jump2-title">{frontmatter.whoWeAre.jumpLinkTitle}</p>
                          <p className="description">{frontmatter.whoWeAre.title}</p>
                          </div>
                          <HTMLContent className="more-info" content={frontmatter.whoWeAre.description}></HTMLContent>
                      </div>
                      </Col>
                  </Row>
                  </Grid>
              </Col>
              
              <Col xs={12} className="third-col full-width" id="jump3">
                  <Grid>
                  <Row className="col-content-wrapper">
                      <Col sm={12} md={6} className="content-wrapper">
                      <div className="wrapper-two">
                          <div className="left-blue-border">
                          <p className="semibold jump3-title">{frontmatter.travelProfessionals.jumpLinkTitle}</p>
                          <HTMLContent className="description" content={frontmatter.travelProfessionals.title}/>
                          </div>
                      </div>
                      </Col>
                      <Col sm={12} md={6} className="image-wrapper no-padding">
                      <img src={frontmatter.travelProfessionals.featureImage} />
                      </Col>
                      <div className="quote-overlay">
                      <p><img src="/uploads/left-quote.svg" /> <span>{frontmatter.travelProfessionals.quote}</span></p>
                      </div>
                  </Row>
                  </Grid>
              </Col>

              <Col xs={12} className="fourth-col full-width">
                  <Grid>
                  <Row className="col-content-wrapper">
                      {
                      frontmatter.marketingPoints.map((feature,i)=>{
                          return (
                          <Col xs={12} md={6} lg={4} className="no-padding" key={i}>
                              <div className="xs-4">
                              <span className="num">{i+1}</span>
                              <span className="text">{feature.title}</span>
                              <div className="clear" />
                              </div>
                          </Col>
                          )
                      })
                      }
                  </Row>
                  </Grid>
              </Col>

              <Col xs={12} className="full-width sixth-col" id="jump5">
                  <Grid>
                  <Row>
                      <Col xs={12}>
                      <h3 className="sales-header jump5-title">{frontmatter.contactInformation.jumpLinkTitle}</h3>
                      </Col>
                  </Row>
                  <Row className="flag-wrapper-row">
                      <Col xs={12}>
                      <Grid className="custom-row">
                          <Row>
                          {
                              frontmatter.salesInformation.map((region,i)=>{
                              return (<Col xs={12} sm={6} md={4} lg={3} key={i}>
                                  <div className="flag-wrapper">
                                  <img className="svg-logo" src={region.icon} />
                                  <p className="country">{region.title}</p>
                                  <p className="link"><a>{region.email}</a></p>
                                  </div>
                              </Col>)
                              })
                          }
                          </Row>
                      </Grid>
                      </Col>
                  </Row>
                  </Grid>
              </Col>
              </Row>
          </Grid>
        </Layout>)
  } else {
      return (
      <div></div>
      )
  }
  
}

export const splashPageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path }}) {
      html
      frontmatter {
        title
        banner {
          welcome
          header
          description
          subdescription
          background
        }
        whoWeAre {
          jumpLinkTitle
          title
          description
          featureImage
        }
        travelProfessionals {
          jumpLinkTitle
          title
          quote
          featureImage
        }
        marketingPoints {
          title
        }
        contactInformation {
          jumpLinkTitle
        }
        salesInformation {
          title
          icon
          email
        }
      }
    }
  }
`